import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import "./index.scss";

interface LoaderProps {
	loading: boolean;
}

const LoadingScreen: React.FC<LoaderProps> = (props) => {
	const { t } = useTranslation();
	const { loadingText } = useSelector((state: RootState) => state.store);

	return (
		<div className={`loader-container ${props.loading ? "w-100" : ""}`}>
			<div className="loader-wrap">
				<span className="loader"></span>
				<h4>{t("Loading")}...</h4>
				{loadingText && (
					<>
						<h5>{loadingText}</h5>
					</>
				)}
			</div>
		</div>
	);
};

export default LoadingScreen;
