import { SHOW_LOADING, HIDE_LOADING, CHANGE_LANGUAGE } from "../actions/types";
import { ActionModel } from "../model/Utilities";

interface AppState {
	loading: boolean;
	language: string;
	loadingText?: string;
}

const localLanguage = localStorage.getItem("Language");

const initialState: AppState = {
	loading: false,
	language: localLanguage || "en",
};

export default function appReducer(state = initialState, action: ActionModel) {
	const { type, payload } = action;

	switch (type) {
		case SHOW_LOADING:
			return {
				...state,
				loading: true,
				loadingText: payload.text,
			};
		case HIDE_LOADING:
			return {
				...state,
				loading: false,
				loadingText: "",
			};
		case CHANGE_LANGUAGE:
			return {
				...state,
				language: payload,
			};
		default:
			return state;
	}
}
