import React, { useState } from "react";
import { Image, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import config from "../../config";
import { useMenu } from "../../menu-items";

// Define types for menu items
interface MenuItem {
	id: string;
	label: string;
	value: string | (() => void); // Type can be string or function
	type: "link" | "action" | string;
	mobile?: boolean;
}

const MobileBottomNav: React.FC = () => {
	const { t } = useTranslation();
	const menu = useMenu();
	const ossUrl = config.ossUrl + "Landing/";
	const location = useLocation(); // Get current location
	const [activeItem, setActiveItem] = useState<string | null>(null); // Track active item

	// Determine if an item is active
	const isActive = (item: MenuItem) => {
		if (item.type === "link") {
			return item.value === location.pathname || activeItem === item.id;
		}
		return activeItem === item.id;
	};

	const handleClick = (itemId: string, itemValue: string | (() => void)) => {
		if (typeof itemValue === "function") {
			itemValue();
		}
		setActiveItem(itemId); // Set active item on click
	};

	return (
		<nav
			id="mobileNav"
			className="navbar fixed-bottom navbar-light p-0"
			role="navigation"
		>
			<Nav className="subnav">
				<div className="d-flex flex-row justify-content-evenly w-100">
					{menu.map((item) => {
						if (!item.mobile) return null;

						const iconSrc = `/icons/${item.id}.png`;
						const activeClass = isActive(item) ? 'active text-black' : '';

						return (
							<div
								key={item.id}
								className={`nav-item`}
								onClick={() => handleClick(item.id, item.value)}
							>
								{item.type === "link" && typeof item.value === "string" ?(
									// Type assertion on if declaration to ensure 'value' is a string
									<Link
										to={item.value} // do not do assertion here, as it will break the build package
										className={`nav-link`}
										onClick={() => setActiveItem(item.id)} // Set active item when link is clicked
									>
										<Image src={iconSrc} alt={item.id} />
										{t(item.label)}
									</Link>
								) : item.type === "action" && typeof item.value === "function" ? (
									<div className={`logout-nav`}>
										<Image src={iconSrc} alt={item.id} />
										{t(item.label)}
									</div>
								) : null}
							</div>
						);
					})}
				</div>
			</Nav>
		</nav>
	);
};

export default MobileBottomNav;
