import { CHANGE_LANGUAGE, HIDE_LOADING, SHOW_LOADING } from './types'

export const showLoading = (text?: string) => ({
  type: SHOW_LOADING,
  payload: {
    text: text
  }
})

export const hideLoading = () => ({
  type: HIDE_LOADING
})

export const changeLanguage = (language: string) => ({
  type: CHANGE_LANGUAGE,
  payload: language
})
