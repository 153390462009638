/* eslint-disable react/no-deprecated */
/* eslint-disable react/react-in-jsx-scope */
import 'bootstrap'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/elegant-icons/style.css'
import '../node_modules/et-line/style.css'
import '../node_modules/font-awesome/css/font-awesome.min.css'
import '../node_modules/react-datetime/css/react-datetime.css'
import App from './App'
import './assets/animated.css'
import './assets/scss/style.scss'
import { I18nLoader } from './i18n'
import * as serviceWorker from './serviceWorker'
import { store } from './store'
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import './assets/css/index.css';
import './assets/css/promotion.css';

ReactDOM.render(
  <I18nLoader>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nLoader>,
  document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
