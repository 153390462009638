import apiCaller from "./axios.service";

const baseUrl: string = "/v1/private/player";

export const updateLanguage = async <T>(
	player_language: string
): Promise<T> => {
	return apiCaller(`${baseUrl}/updateLanguage`, { player_language });
};

export const getProfile = () => {
	return apiCaller(`${baseUrl}/getProfile`);
};

export const getGameCategoryList  = () => {
	return apiCaller(`${baseUrl}/getGameCategoryList `);
};

export const getGameList = async <T>({
	game_list_game_category_id,
	provider_category_id
}: {
	game_list_game_category_id?: string;
	provider_category_id?: string;
}): Promise<T> => {
	return apiCaller(`${baseUrl}/getGameList`, {
		game_list_game_category_id,
		provider_category_id
	});
};

export const generateGameUrl = async <T>(
	game_list_game_id: number,
	lobbyURL: string
): Promise<T> => {
	return apiCaller(`${baseUrl}/generateGameUrl`, {
		game_list_game_id,
		lobbyURL,
	});
};
