import { faBars, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Image, Row, Modal, Button, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-toggle/style.css";
import config from "../../config";
import { isNullOrUndefined } from "../../utils";
import { changeLanguage } from "../../actions/store";
import { languages } from "../../constants/language";
import { useMenu } from "../../menu-items";
import Coin from "../../assets/img/icon/coin.png"
import ExampleBanner from "../../assets/img/banner.jpg"
import Language from "../../assets/img/icon/language.png"
import Music from "../../assets/img/icon/music.png"
import Logout from "../../assets/img/icon/logout.png"
import Download from "../../assets/img/icon/download.png"

const Header: React.FC = () => {
	const { t } = useTranslation();
	const { userProfile } = useSelector((state: any) => state.userProfile);
	const ossUrl = config.ossUrl + "Landing/";
	const dispatch = useDispatch();
	const menu = useMenu();
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const currentLanguage = localStorage.getItem("Language") || "en";

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const header = document.getElementById("myHeader");
		const sticky = header?.offsetTop || 0;

		const scrollCallBack = () => {
			if (window.pageYOffset > sticky) {
				header?.classList.add("sticky");
			} else {
				header?.classList.remove("sticky");
			}
		};

		window.addEventListener("scroll", scrollCallBack);

		return () => {
			window.removeEventListener("scroll", scrollCallBack);
		};
	}, []);

	const headerStyle = isNullOrUndefined(userProfile)
		? { padding: "0px .5rem" }
		: {};

	const [activeDropdown, setActiveDropdown] = useState(null);

	const handleDropdownToggle = (index, isOpen) => {
		if (isOpen) {
		setActiveDropdown(index);  // Open the dropdown
		} else if (activeDropdown === index) {
		setActiveDropdown(null);  // Close the dropdown
		}
	};

	const [checked, setChecked] = useState(false);

	const handleToggle = (e) => {
	  setChecked(e.target.checked);
	};

	const mobileSidebar = (
		<div
		className="offcanvas offcanvas-end"
		id="offcanvasExample"
		aria-labelledby="offcanvasExampleLabel"
		style={{
			marginTop: 45,
			marginRight: 55,
			height: activeDropdown != null ? 218 : 180,
			// width: 'fit-content',
			maxWidth: 240,
			borderRadius: 10
		}}
		>
		<div id="sidemenu" className="offcanvas-body m-0">
			{menu.map((item, index) => {
			if (!item.offCanvas) {
				return null;
			}
			return (
				<>
				<Dropdown key={index} onToggle={(isOpen) => handleDropdownToggle(index, isOpen)}
				style={{
					height: activeDropdown != null ? 90 : 'fit-content',
					borderBottom: '1px solid grey'
				}}>
					<Dropdown.Toggle variant="secondary" id={`dropdown-basic-${index}`} 
						style={{
							display: 'flex', 
							alignContent: 'center', 
							justifyContent: 'space-between',
							backgroundColor: 'rgb(32, 32, 32)', 
							border: 'none', 
							padding: '10px 10px'
						}}
					>
					<div className="language_title">
						<img
							src={Language} 
							alt="Language Icon" 
							className="sidebar_icon"
						/>{t("Language")} 
					</div>		
					<div className="language_icon">
						{currentLanguage === "en" ? 'EN' :
						currentLanguage === "zh" ? '中文' :
						currentLanguage === "ms" ? 'BM' : null}
					</div>
					</Dropdown.Toggle>

					<Dropdown.Menu>
						{languages.map((lang, langIndex) => (
								<Dropdown.Item
									key={langIndex}
									onClick={(e) => {
										dispatch(changeLanguage(lang.value));
										localStorage.setItem("Language", lang.value);
									}}
									data-bs-dismiss="offcanvas"
									style={{
										flex: 1,
										padding: '0 10px',
										alignContent: 'center',
										margin: 5, 
										fontSize: 15,
										justifyContent: 'center',
										backgroundColor: lang.value === currentLanguage ? 'rgba(255, 165, 0, 0.2)' : '#333',
										color: lang.value === currentLanguage ? 'rgb(255, 165, 0)' : 'rgb(217, 217, 217)', 
										border: lang.value === currentLanguage ? '2px solid rgb(255, 165, 0)' : 'none',
										borderRadius: 6, 
										boxShadow: lang.value === currentLanguage ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'
									}}
								>
									{lang.value === "en" ? ' EN ' :
									lang.value === "zh" ? '中文' :
									lang.value === "ms" ? 'BM' : null}
								</Dropdown.Item>
							
						))}
					</Dropdown.Menu>
				</Dropdown>

				<div className="side-selection" key={index}>
					<div  className="flex">
						<img 
							src={Download} 
							alt="Icon" 
							className="sidebar_icon"
						/> {t("Download")}
					</div>
				</div>

				<div className="side-selection" key={index}>
					<div  className="flex">
						<img 
							src={Music} 
							alt="Icon" 
							className="sidebar_icon"
						/> {t("music")}
					</div>
					<Form>
						<Form.Check 
							type="switch"
							checked={checked}
							onChange={handleToggle}
							className="custom-switch"
						/>
					</Form>
				</div>

				<div className="side-selection" key={index}>
					<div  className="flex">
						<img 
							src={Logout} 
							alt="Icon" 
							className="sidebar_icon"
						/> {t("Logout")}
					</div>
				</div>

				</>
			);
			})}
		</div>
		</div>
	);

	return (
		<header id="myHeader" style={headerStyle}>
			<Row className="align-items-center">
			<div style={{
				display: 'flex',
				justifyContent: isMobile ? 'flex-end' : 'space-between',
				alignItems: 'center',
				backgroundImage: isMobile ? `url(${ExampleBanner})` : '',
				backgroundSize: isMobile ? 'cover' : '',
				backgroundPosition: isMobile ? 'center' : '',
				height: isMobile ? '140px' : ''
			}}>
				{!isMobile && (
					<div className="balance-bar">
						<div style={{display: 'flex', alignItems: 'center'}}>
							<Image src={Coin} style={{width: 28}}/>
							<p className="m-0">
								{t("balance")}: 0
							</p>
						</div>
						<FontAwesomeIcon icon={faRedo} style={{ color: 'white', fontSize: 17}}  />
					</div>				
				)}
				<div
					data-bs-toggle="offcanvas"
					data-bs-target="#offcanvasExample"
					aria-controls="offcanvasExample"
				>
					<div style={{backgroundColor: 'rgba(0,0,0,0.7)', borderRadius: 55, height: 40, width: 40, alignContent: 'center', textAlignLast: 'center', marginRight: 10}}>
						<FontAwesomeIcon icon={faBars} className="" style={{ color: 'white', fontSize: 20}}  />
					</div>
				</div>
				{mobileSidebar}
			</div>

			{isMobile && (
				<div className="balance-bar">
					<div style={{display: 'flex', alignItems: 'center'}}>
						<Image src={Coin} className="" style={{width: 28}}/>
						<p className="m-0">
							{t("balance")}: 0
						</p>
					</div>
					<FontAwesomeIcon icon={faRedo} className="" style={{ color: 'white', fontSize: 17}}  />
				</div>				
			)} 
				
			{/* {!isNullOrUndefined(userProfile) && (
				<Col xs="auto">
					<h6 className="m-0">
						{t("Welcome back")}, {userProfile.player_nickname}
					</h6>
				</Col>
			)} */}
			</Row>

		</header>
	);
};

export default Header;
