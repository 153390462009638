export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE'
