import { LoginParams, LoginResult } from "../model/User";
import apiCaller from "./axios.service";

const baseUrl: string = "/v1/public/identity";

export const login = async (data: LoginParams): Promise<LoginResult> => {
	return apiCaller(`${baseUrl}/signIn`, data);
};

export const signOut = () => {
	return apiCaller(`${baseUrl}/signOut`);
};
