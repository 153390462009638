import { combineReducers } from 'redux'
import store from './store'
import auth from './auth'
import userProfile from './userProfile'

export default combineReducers({
  auth,
  userProfile,
  store
})
