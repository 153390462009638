import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT } from "../actions/types";
import { PlayerModel } from "../model/User";
import { ActionModel } from "../model/Utilities";

interface AuthState {
	user: PlayerModel | null;
	isLoggedIn: boolean;
}

const initialState: AuthState = {
	user: null,
	isLoggedIn: false,
};

export default function (state = initialState, action: ActionModel) {
	const { type, payload } = action;
	switch (type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				isLoggedIn: true,
				userProfile: payload.user,
				token: payload.token,
			};
		case LOGIN_FAIL:
			return {
				...state,
				isLoggedIn: false,
				userProfile: null,
				token: null,
			};
		case LOGOUT:
			return {
				...state,
				isLoggedIn: false,
				userProfile: null,
				token: null,
			};
		default:
			return state;
	}
}
