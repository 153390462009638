import config from "../config";
const ossUrl = config.ossUrl;

export const languages = [
	{
		label: "ENG",
		value: "en",
		img: ossUrl + "uk.png",
	},
	{
		label: "中文",
		value: "zh",
		img: ossUrl + "cn.png",
	},
	{
		label: "MS",
		value: "ms",
		img: ossUrl + "ms.png",
	},
];
