import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import config from "../config";

axios.defaults.baseURL = config.apiURL;

interface ErrorResponse {
	response?: {
		data: any;
		status: number;
		headers: any;
	};
	request?: any;
	message: string;
}

const handleError = (error: ErrorResponse): never => {
	if (error.response) {
		// Request made and server responded
		console.log(error.response.data);
		console.log(error.response.status);
		console.log(error.response.headers);
	} else if (error.request) {
		// The request was made but no response was received
		console.log(error.request);
	} else {
		// Something happened in setting up the request that triggered an Error
		console.log("Error", error.message);
	}

	throw error;
};

interface ApiCallerOptions {
	DELETE: "delete";
	GET: "get";
	HEAD: "head";
	OPTIONS: "options";
	PATCH: "patch";
	POST: "post";
	PUT: "put";
}

export default function apiCaller(
	path: string,
	data: any = {},
	method: any = "POST"
): Promise<any> {
	const methods: ApiCallerOptions = {
		DELETE: "delete",
		GET: "get",
		HEAD: "head",
		OPTIONS: "options",
		PATCH: "patch",
		POST: "post",
		PUT: "put",
	};

	const localToken = localStorage.getItem("user_token");
	const sessionToken = sessionStorage.getItem("user_token");
	axios.defaults.headers.AuthorizationPath = window.location.pathname;
	axios.defaults.headers.Authorization = `Bearer ${localToken || sessionToken}`;
	axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.Language = `${localStorage.getItem("Language")}`;

	// const func = axios[methods[method] || "post"] as (
	// 	url: string,
	// 	data?: any,
	// 	config?: AxiosRequestConfig
	// ) => Promise<AxiosResponse>;

	const func: (
		url: string,
		data?: any,
		config?: AxiosRequestConfig
	) => Promise<AxiosResponse> = axios[methods[method] || "post"];

	return func(path, data)
		.then((response: AxiosResponse) => response.data)
		.catch((error: ErrorResponse) => handleError(error));
}
