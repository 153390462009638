export function getQueryParam(prop: string) {
	const params: { [key: string]: string } = {};
	const search = decodeURIComponent(
		window.location.href.slice(window.location.href.indexOf("?") + 1)
	);
	const definitions = search.split("&");

	definitions.forEach(function (val: string) {
		const parts = val.split("=", 2);
		params[parts[0]] = parts[1];
	});

	return prop && prop in params ? params[prop] : "";
}

//Format for display whole number and number with decimal with comma
export function processNumValue(num: any, val: any) {
	if (isNullOrUndefined(num)) {
		return 0;
	}
	let withDecimals = num.toString().match(/^-?\d+(?:\.\d+)?/);
	withDecimals = Number(withDecimals);

	// Check if number has decimal or it's a whole number
	const result = num - Math.floor(num) !== 0;
	if (result) {
		withDecimals = withDecimals.toLocaleString(undefined, {
			minimumFractionDigits: val,
			maximumFractionDigits: val,
		});

		// Remove trailing zeros after the decimal point
		withDecimals = withDecimals.replace(/(\.\d*?[1-9])0+$/, "$1");
	} else {
		withDecimals = withDecimals.toLocaleString(undefined, {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
	}
	return withDecimals;
}

export const isNullOrUndefined = (value: any): boolean => {
	return value === null || value === undefined || value === "";
};

// ELLIPSIS USER METAMASK ADDRESS
export function trimAddress(address: string) {
	let trimAddress: string;
	if (address) {
		trimAddress =
			address.substring(0, 6) + "****" + address.substring(address.length - 4);
		return trimAddress;
	} else {
		return "-";
	}
}

export function isValidEmail(value: string) {
	// Regular expression for a basic email format
	const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/;
	return emailPattern.test(value);
}

export function abbreviateNumber(number) {
	const SI_SYMBOL = ["", "K", "M", "B", "T"];
	const tier = Math.log10(Math.abs(number)) / 3 | 0;

	if (tier == 0) return number;

	const suffix = SI_SYMBOL[tier];
	const scale = Math.pow(10, tier * 3);
	const scaled = number / scale;

	return scaled.toFixed(2) + suffix;
}