import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { useEffect, useState, ReactNode } from 'react'
import { initReactI18next } from 'react-i18next'

interface I18nLoaderProps {
  children: ReactNode
}

export const I18nLoader = ({ children }: I18nLoaderProps) => {
  const [initialized, setInitialized] = useState(false)

  const init = () =>
    i18n
      .use(Backend)
      .use(initReactI18next)
      .init({
        lng: 'en',
        backend: {
          /* translation file path */
          loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
        },
        fallbackLng: 'en',
        debug: false,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
          escapeValue: false,
          formatSeparator: ','
        }
      })

  useEffect(() => {
    if (!i18n.isInitialized) {
      init().then(() => setInitialized(true))
    }
  }, [])

  if (initialized) return children

  return null
}

export default i18n
